import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
import {
  Box,
  styled,
  Divider,
  Typography,
  CircularProgress,
} from "@mui/material";
import backtodash from "../assets/img/back-to-dash.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPowerOff,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import {
  decryptMessage,
  formatDate,
  removeCookie,
  truncateString,
} from "../Config/Helper";
import { useDispatch, useSelector } from "react-redux";
import {
  filterFromSideBar,
  getMyBlockList,
  getMyConversations,
  isReCallSideBar,
  searchConnectList,
  userUnBlock,
} from "../Config/Store/chatReducer";
import blockicon from "../assets/img/block-icon.png";
import DummyProfileImage from "../assets/img/default_profile_image.png";
import BackArrow from "../assets/img/great-backarrow.png";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { turnOffSocket } from "../Config/Socket";
import Logo from "../assets/img/logo.svg";
import DirectchatIcon from "../assets/img/directchatIcon.svg";
import CloseIcon from "../assets/img/closeIcon.svg";
import InboxIcon from "../assets/img/inboxIcon.svg";
const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    const context = this;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(context, args), delay);
  };
};

const ComponentParent = styled(Box)`
  overflow: overlay;
  height: 81vh;
`;

const ComponentChild = styled(Box)`
  height: 45px;
  display: flex;

  cursor: pointer;
  margin-bottom: 20px;
`;
const Container = styled(Box)`
  display: flex;
`;
const StyledDivider = styled(Divider)`
  margin: 0 0 0 70px;
  background-color: #e9edef;
  opacity: 0.6;
`;
const Timestamp = styled(Typography)`
  font-size: 12px;
  margin-left: auto;
  color: #00000099;
  margin-right: 20px;
`;
const Image = styled("img")({
  width: 100,
  height: 50,

  objectFit: "cover",
  borderRadius: "50%",
  padding: "0 14px",
});
const Text = styled(Typography)`
  display: block;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
`;
function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation()
  const dispatch = useDispatch();
  const uId = useParams();
  const pathParts = location.pathname.split('/');
  const USERID = pathParts[1];
  console.log(USERID, "uIduIduIduIduIduIduIduIduIduId")

  useEffect(() => {
    console.log("param ki id sidebar me ", uId)

    // return () => {
    //     socket.off("receiveMessage");
    // };
  }, [USERID]);



  const {
    searchList = [],
    allconnection = [],
    blockedUserList = [],
    blockListLoader = false,
    reCallSideBar,
    sideBarSearch = "",
    directChatLoader = false,
    directChatSearch = [],
  } = useSelector((store) => store.chat);
  const [isSpanVisible, setIsSpanVisible] = useState(false); // for + dropdown show
  const [directChatModal, setDirectChatModal] = useState(false); // for direct chat modal
  const [directChatModalActual, setDirectChatModalActual] = useState(false); // for direct chat modal
  const [searchUserChat, setSearchUserChat] = useState(""); // for search chat on direct chat modal
  const [blockListDisplay, setBlockListDisplay] = useState(false); // for block list hide & show


  const showBlockListUser = async () => {
    setBlockListDisplay((prev) => !prev);
    dispatch(getMyBlockList());
  };

  let connectionsList = sideBarSearch ? searchList : allconnection;

  const userUnBlockFromList = async (id) => {
    await dispatch(userUnBlock(id));
    dispatch(getMyConversations());

    setBlockListDisplay(false);
    dispatch(isReCallSideBar());
  };

  const debounceSearch = useRef(
    debounce((val) => dispatch(searchConnectList(val)), 1000)
  );
  const ref = useRef(null);

  useEffect(() => {
    const handleOverlayClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        const delay = (milliseconds) => {
          return new Promise((resolve) => setTimeout(resolve, milliseconds));
        };

        const handleAction = async () => {
          await delay(100);
          setIsSpanVisible(false);
        };

        handleAction(); // Call handleAction to start the delay
      }
    };

    document.addEventListener("mousedown", handleOverlayClick);

    return () => {
      document.removeEventListener("mousedown", handleOverlayClick);
    };
  }, []);

  const handleDirectSaerchChat = (value) => {
    setSearchUserChat(value);
    debounceSearch.current(value);
  };

  useEffect(() => {
    dispatch(getMyConversations());
  }, [reCallSideBar]);

  useEffect(() => {
    dispatch(searchConnectList(""));
  }, []);

  // modal add class body
  useEffect(() => {
    // Add class to body when modal is open
    if (directChatModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    // Cleanup effect
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [directChatModal]);

  useEffect(() => {
    if (directChatModalActual) {
      navigate("/")
    }
  }, [directChatModalActual])
  return (
    <div className="chat-left">
      <aside className="fixed sideBar h-screen bg-darkgreen">
        <div className="flex justify-center my-5">
          <img className="chatbar-left-logo" src={Logo} alt="" />
        </div>{" "}
        {/* logo image */}
        <div className="main-content-chat-sidebar-parent">
          <a
            href={`${process.env.REACT_APP_DASHBOARD_URL}/feed`}
            className="flex items-center justify-center gap-4"
            style={{ marginBottom: "20px" }}
          >
            <img src={backtodash} alt="" />
            <span className="font-size-17px text-white font-Poppins-Regular text-[#00443f]">
              Back to dashboard
            </span>
          </a>{" "}
          {/* back to dashboard button */}
          <div className="flex items-center justify-between chat-app-inbox-sidebar-heading relative">
            <div className="flex items-center gap-2">
              <img
                src={InboxIcon}
                alt=""
              />
              <span className="font-size-17px text-white font-poppins-regular">
                Inbox
              </span>
            </div>
            <div className="flex justify-center items-center">
              <button
                onClick={() => setIsSpanVisible((prev) => !prev)}

              >
                <img src={DirectchatIcon} />
              </button>
              <img
                className="cursor-pointer"
                // src="https://edgie.s3.ap-southeast-2.amazonaws.com/imgs/plus-comp.png"
                alt=""
              />
              {isSpanVisible && (
                <div ref={ref} className="absolute z-10 right-0 w-full bg-white direct-chat-parent-box flex flex-col">
                  <button
                    className="delete-convo-box-btn text-start font-size-15px color-seven-zero"
                    onClick={() => {
                      setIsSpanVisible(false)
                      setDirectChatModal(false);
                      setDirectChatModalActual(true);
                    }}                  >
                    Direct chat
                  </button>
                  {/* <button
                                        className="delete-convo-box-btn text-start font-size-15px color-seven-zero"
                                    >
                                        Group Chat
                                    </button> */}
                </div>
              )}
            </div>
          </div>
          {/* Direct Chat Modal */}
          {directChatModalActual && (
            <div className="modal-overlay-chat-app">
              <div className="modal-chat-app flex flex-col bg-cream relative">
                <img
                  onClick={() => setDirectChatModalActual(false)}
                  className="cursor-pointer absolute modalcancelicon"
                  src={CloseIcon}
                  alt=""
                />
                <div className="modal-content-chat-app">
                  <h2 className="font-poppins-semibold font-size-40px text-center color-black-four-six leading-none">
                    Direct chat
                  </h2>
                </div>
                <div>
                  <input
                    className="w-full chat-modal-search-input font-poppins-regular font-size-15px outline-none color-black-four-six"
                    placeholder="Search to start a chat"
                    type="search"
                    value={searchUserChat}
                    onChange={(e) => handleDirectSaerchChat(e.target.value)}
                  />
                </div>
                <h3
                  className="color-black-four-six font-poppins-semibold font-size-17px"
                  style={{ color: "#00443f" }}
                >
                  All Connections
                </h3>
                <div className="flex flex-col direct-chat-start-parent">
                  {directChatLoader && (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress
                        style={{ color: "#00443f", width: 20, height: 20 }}
                      />
                    </div>
                  )}
                  {directChatSearch?.map((item, index) => (
                    <div
                      className="flex items-start direct-chat-start justify-between "
                      key={index}
                    >
                      <Link
                        className="flex items-center gap-2 relative"
                        style={{ cursor: "pointer" }}
                        to={`/${item?.userId}/chat`}
                        onClick={() => setDirectChatModalActual(false)}
                        role="button"
                      >
                        {/* <div  className={user?.statusOnline  ? "circle-if-online user-online" : "circle-if-online user-offline"}>     </div> */}
                        <img
                          className="chatuserimg"
                          src={`${process.env.REACT_APP_IMAGE_URL}${item?.profileImageUrl}`}
                          alt={item?.firstName || ""}
                        />

                        <div className="flex flex-col justify-between">
                          <span
                            className="font-poppins-medium font-size-17px color-black-four-six"
                            style={{ textTransform: "capitalize" }}
                          >
                            <span>{`${item?.firstName || ""} ${item?.lastName || ""
                              }`}</span>
                          </span>
                          <span
                            className="font-size-12px"
                            style={{ color: "#AFAFAF" }}
                          >
                            {item?.userRole || ""}
                          </span>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {/* Direct Chat Modal */}
          <>
            {!blockListDisplay ? (
              <div>
                <div className="chats-parent flex flex-col">
                  <div className="chats-inner-parent chats-inner-active relative">
                    <div className="search-sidebar-chat-parent text-white relative mb-3 flex justify-center">
                      <div className="flex flex-row items-center gap-2 w-[70%]">
                        <FontAwesomeIcon
                          // className="search-icon-leftbar-chat "
                          icon={faMagnifyingGlass}
                        />
                        <input
                          className="font-Poppins-Regular bg-transparent search-sidebar-chat-input outline-none"
                          placeholder="Search for people and groups"
                          type="search"
                          value={sideBarSearch}
                          onChange={(e) =>
                            dispatch(filterFromSideBar(e.target.value))
                          }
                        />
                      </div>
                    </div>
                    <ComponentParent>
                      {connectionsList && connectionsList.length > 0 ? (
                        connectionsList?.map((item, index) => (
                          <React.Fragment key={index}>
                            <ComponentChild
                              onClick={() => navigate(`/${item?.userId}/chat`)}
                              className={`sidebarusers ${USERID === item?.userId ? "yellow-line" : ""}`}
                            >
                              <Box>
                                <div className="relative user-online">
                                  <Image
                                    src={`${process.env.REACT_APP_IMAGE_URL}${item?.profileImageUrl}`}
                                    onError={(e) =>
                                      (e.target.src = DummyProfileImage)
                                    }
                                    alt="display picture"
                                  />
                                  {/* className="circle-if-online user-online"> */}
                                  <div
                                    className={
                                      item?.statusOnline
                                        ? "circle-if-online user-online"
                                        : "circle-if-online user-offline"
                                    }
                                  >
                                    <div
                                      className={
                                        item?.UnreadMessages === 0
                                          ? "newmessageusers hidden"
                                          : "newmessageusers"
                                      }
                                    >
                                      {item?.UnreadMessages !== 0 &&
                                        item?.UnreadMessages}
                                    </div>
                                  </div>
                                </div>
                                <span></span>
                              </Box>
                              <Box style={{ width: "100%" }}>
                                <Container>
                                  <Typography
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {item?.firstName || ""}{" "}
                                    {item?.lastName || ""}
                                  </Typography>
                                  {item?.lastMessageTime && (
                                    <Timestamp>
                                      {formatDate(item?.lastMessageTime)}
                                    </Timestamp>
                                  )}
                                </Container>
                                <Box>
                                  <Text>
                                    {item?.lastMessageType === "text" ? (
                                      <p>
                                        {truncateString(
                                          decryptMessage(item?.lastMessage),
                                          35
                                        ) || ""}
                                      </p>
                                    ) : (
                                      item?.lastMessage
                                    )}
                                  </Text>
                                </Box>
                              </Box>
                            </ComponentChild>
                            {allconnection.length !== index + 1 && (
                              <StyledDivider />
                            )}
                          </React.Fragment>
                        ))
                      ) : (
                        <p className="text-center text-white">No Message</p>
                      )}
                    </ComponentParent>
                  </div>
                </div>
                <div className="chat-block-btn flex items-center gap-2 text-white">
                  <button
                    onClick={showBlockListUser}
                    className="flex flex-row items-center gap-2"
                  >
                    <img src={blockicon} alt="" />
                    <span className="font-size-15px font-Poppins-Regular cursor-pointer">
                      See block list
                    </span>
                  </button>
                </div>
              </div>
            ) : null}
          </>
          {blockListDisplay && (
            <>
              <div className="flex items-center justify-between chat-seeblocklist-btn-parent">
                <div className="flex items-center gap-2 text-white">
                  <img src={blockicon} alt="" />
                  <span className="font-size-15px">Block List</span>
                </div>
                <button onClick={() => setBlockListDisplay((prev) => !prev)}>
                  {" "}
                  <img className="cursor-pointer" src={BackArrow} alt="" />
                </button>
              </div>

              <div className="chats-parent flex flex-col mt-5">
                {blockListLoader ? (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress
                      style={{ width: 18, height: 18, color: "#fff" }}
                    />
                  </div>
                ) : blockedUserList.length === 0 ? (
                  <p className="text-white text-center">No blocked users</p>
                ) : (
                  blockedUserList?.map((item, index) => (
                    <div
                      key={index}
                      className="chats-inner-parent relative flex items-start justify-between px-2"
                    >
                      <div className="flex items-start gap-2 items-center">
                        <div className="relative">
                          <img
                            className="chatuserimg"
                            style={{
                              backgroundColor: "#fff",
                              borderRadius: "50%",
                            }}
                            src={`${process.env.REACT_APP_IMAGE_URL}${item?.profileImageUrl}`}
                            onError={(e) => (e.target.src = DummyProfileImage)}
                            alt=""
                          />
                        </div>
                        <div className="text-white">
                          <h5
                            className="font-size-15px font-Poppins-Regular"
                            style={{ textTransform: "capitalize" }}
                          >
                            {item?.fullName || ""}
                            {/* {`${item?.firstName || ""} ${item?.lastName || ""}`} */}
                          </h5>
                          <h6
                            className="font-size-12px"
                            style={{ color: "#AFAFAF" }}
                          >
                            {item?.userRole || ""}
                          </h6>
                        </div>
                      </div>
                      <button
                        onClick={() => userUnBlockFromList(item?.blockedUserId)}
                        type="button"
                        className="bg-white unblockchat-side font-size-15px font-Poppins-Regular"
                      >
                        Unblock
                      </button>
                    </div>
                  ))
                )}
              </div>
            </>
          )}
          <></>
          <button
            onClick={() => {
              turnOffSocket();
              removeCookie();
            }}
            className="logout-chat flex items-center gap-2 justify-center absolute bottom-0 left-0 right-0 mx-auto"
          >
            <FontAwesomeIcon icon={faPowerOff} />
            <span className="font-Poppins-Bold">Logout</span>
          </button>
        </div>
      </aside>
    </div>
  );
}

export default Sidebar;
